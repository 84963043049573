import { computed } from "vue";

export function useNotifications(notification, emit) {

    const statusColor = {
        new: 'success',
        updated: 'info',
        deleted: 'danger',
        special: 'contrast'
    };
    
    const resolveTagColor = (status) => statusColor[status] || '';    

    const entityId = computed(() => notification.value?.entity_id);
    const avatar = computed(() => notification.value?.avatar);
    const text = computed(() => notification.value?.title);
    const time = computed(() => notification.value?.time);
    const age = computed(() => notification.value?.age);
    const sex = computed(() => notification.value?.sex);
    const preTitle = computed(() => notification.value?.pre_title);
    const status = computed(() => notification.value?.status);

    const readNotification = () => {
        emit("readNotification", notification.value?.id);
    };

    return {
        readNotification,
        resolveTagColor,
        entityId,
        avatar,
        text,
        time,
        age,
        sex,
        preTitle,
        status,
    };
}