<template>
    <AutoComplete 
        v-model="selectedPlace" 
        forceSelection 
        :suggestions="predictions"
        optionLabel="description" 
        @complete="autocompleteInputHandler" 
        @option-select="handleSelectedPlace"
        :invalid="invalid"
        :inputId="inputId"
        :pt="{ pcInputText: { root: { autocomplete: 'new' } } }"
    />
</template>

<script>
const ADDRESS_COMPONENTS = {
    subpremise : 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
};
import { Loader } from '@googlemaps/js-api-loader';

export default {
    name: 'AddressAutocomplete',
    props: ['name', 'modelValue', 'label', 'class'],
    props: {
        modelValue: [Object, String],
        fullResponse: {
            type: Boolean,
            default: false
        },
        formated: {
            type: Boolean,
            default: true
        },
        label: String,
        name: String,
        class: String,
        invalid: {
            type: Boolean,
            required: false,
            default: false
        },
        inputId: String
    },
    data() {
        return {
            autocompleteService: null,
            geocoder: null,
            predictions: [],
            selectedPlace: this.modelValue,
            newSearch: ''
        };
    },
    mounted() {
        const loader = new Loader({
            apiKey: 'AIzaSyAckEiJcwKyMHThypkVsWNDmg1T4oMxOMY',
            version: 'weekly',
            libraries: ['places', 'geocoding'],
            language: 'pl',
        });

        loader.load().then(() => {
            this.initAutocomplete();
        });
    },
    methods: {
        initAutocomplete() {
            // Initialize autocomplete service
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.geocoder = new google.maps.Geocoder();
            //this.placesService = new google.maps.PlacesService();
            // Optional: Set initial value based on modelValue if available
            if (this.modelValue && this.modelValue.formatted_address) {
                this.selectedPlace = {
                    description: this.modelValue.formatted_address,
                    place_id: this.modelValue.place_id // Assuming you have place_id in your modelValue
                };
                this.autocompleteInputHandler();
            }
        },
        autocompleteInputHandler(input) {
            //console.log(input.query);
            // Perform autocomplete request
            if (input && input?.query?.length > 0) {
                this.autocompleteService.getPlacePredictions(
                    {
                        input: input.query,
                        types: ['address'],
                        componentRestrictions: { country: 'pl' }
                    },
                    this.autocompleteCallback
                );
            } else {
                this.predictions = [];
            }
        },
        autocompleteCallback(predictions, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                // Update predictions with new suggestions
                this.predictions = predictions;
            } else {
                console.error('Autocomplete prediction request failed with status:', status);
            }
        },
        handleSelectedPlace(event) {
            const prediction = event.value;
            const place_id = prediction.place_id;
            //const prediction = this.predictions.find(place => place.place_id === place_id);
            this.geocoder.geocode({ placeId: place_id }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    
                    if (results[0]) {
                        let place = results[0];
                        place.prediction = prediction;

                        if (this.formated){
                            place.formatted_address = this.formatResult(place);
                        }
                        
                        if(!this.fullResponse){
                            place = this.formated ? place.formatted_address : place.address_components;
                        }

                        place.description = prediction.description;

                        this.$emit('update:modelValue', place);
                    }
                    else{
                        this.$emit('update:modelValue', {});
                    }
                } else {
                    console.error('Geocoder failed due to:', status);
                }
            });
        },
        updateValue(newValue) {
            // Emit the updated value using v-model
            this.$emit('input', newValue);
        },
        formatResult (place) {
            let returnData = {};
            for (let i = 0; i < place.address_components.length; i++) {
                let addressType = place.address_components[i].types[0];

                if (ADDRESS_COMPONENTS[addressType]) {
                    let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                    returnData[addressType] = val;
                }
            }

            returnData['latitude'] = place.geometry.location.lat();
            returnData['longitude'] = place.geometry.location.lng();
            return returnData
        },
    },
};
</script>