<template>
    <div class="card !p-2 !mb-0 flex flex-row gap-4 items-center border border-[#e2e8f0]">
        <Avatar :image="avatar" size="large" class="min-w-[30px] w-[30px] min-h-[30px] h-[30px] !rounded-md !overflow-hidden"></Avatar>
        <div class="flex flex-col w-full mb-1">
            <div class="flex justify-between mb-1">
                <div class="flex items-center gap-2">
                    <span class="text-sm font-bold">
                        {{ text }}
                    </span>
                    <span v-if="sex == 1" class="material-symbols-outlined text-blue-600 text-xl" translate="no">male</span>
                    <span v-else-if="sex == 2" class="material-symbols-outlined text-pink-600 text-xl" translate="no">female</span>
                    <span v-else-if="sex == 3" class="material-symbols-outlined text-indigo-600 text-xl" translate="no">family_restroom</span>
                    <Badge class="text-sm/8 me-2" v-if="age > 0" :value="age" severity="info" size="small"></Badge>
                </div>
                <div class="flex items-center">
                    <i @click.prevent.stop="readNotification" class="cursor-pointer pi pi-times !text-[9px] self-baseline text-[#64748b]"></i>
                </div>
            </div>
            <div class="flex flex-row items-center justify-between">
                <div>
                    <div class="flex flex-row gap-2">
                        <Tag v-if="preTitle" :pt="{ label: { class: 'text-xs' } }" :severity="resolveTagColor(status)" :value="preTitle"></Tag>
                    </div>
                </div>
                <div class="flex flex-row items-center self-end mb-[-3px]">
                    <span class="text-xs text-slate-500">{{ time }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { Link, Head, router, usePage} from "@inertiajs/vue3";
import { useNotifications } from '../../Composables/useNotifications';

export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const notification = toRefs(props).notification;

        const {
            readNotification,
            resolveTagColor,
            entityId,
            avatar,
            text,
            time,
            age,
            sex,
            preTitle,
            status
        } = useNotifications(notification, emit);

        return {
            readNotification,
            resolveTagColor,
            entityId,
            avatar,
            text,
            time,
            age,
            sex,
            preTitle,
            status
        };
    },
}   
</script>