<template>
    <div>
        <Head :title="$t('Vacancy parser')" />
        <ConfirmPopup></ConfirmPopup>

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ $t('Vacancy parser') }}</div>
                <small class="text-red-400 ms-2">Beta</small>
            </template>

            <template #center>

            </template>

            <template #end>

            </template>
        </Toolbar>

        <Stepper :value="step" linear>
            <StepList>
                <Step :value="1">Загрузка файла</Step>
                <Step :value="2">Деактивация</Step>
                <Step :value="3">Изменение</Step>
                <Step :value="4">Результаты</Step>
            </StepList>
            <StepPanels>
                <StepPanel v-slot="{ activateCallback }" :value="1">
                    <div class="flex flex-row justify-between py-5">
                        <div class="flex flex-col grow basis-1/2 items-center justify-center py-5">
                            <FileUpload mode="basic" @select="onFileSelect" customUpload auto severity="secondary"
                                class="p-button-outlined" accept=".html" />
                        </div>
                        <div>
                            <Divider layout="vertical" />
                        </div>

                        <div class="flex flex-col grow basis-1/2 items-center justify-center mt-2">
                            <div v-if="parsable && !parsableError" class="flex flex-col items-center gap-3">
                                <div class="md:w-10 relative">
                                    <img class="block xl:block mx-auto rounded w-full" :src="parsable?.logo"
                                        :alt="parsable?.name" />
                                </div>
                                <div class="text-lg font-medium">{{ parsable?.name }}</div>
                            </div>
                            <div class="text-lg text-red-400" v-else-if="parsableError">
                                Не удалось определить тип файла
                            </div>
                            <div v-if="parsable?.statistics" class="w-full px-10 mt-2">
                                <MeterGroup :value="parsable?.statistics" :max="parsable?.count">
                                    <template #start="{ totalPercent }">
                                        <span>Участвует в парсинге</span>
                                        <div class="flex justify-between relative">
                                            <span></span>
                                            <span :style="{ width: totalPercent + '%' }" class="absolute text-right">{{ totalPercent }}%</span>
                                            <span class="font-medium">{{ parsable?.count }}</span>
                                        </div>
                                    </template>
                                    <!-- <template #label="{ value }">
                                        <ol class="p-metergroup-label-list p-metergroup-label-list-horizontal">
                                            <li v-for="item in value" class="p-metergroup-label">
                                                <span 
                                                    class="p-metergroup-label-marker" 
                                                    data-pc-section="labelmarker"
                                                    :style="'background-color: '+item?.color">
                                                </span>
                                                <span class="p-metergroup-label-text">
                                                    {{ item.label }} ({{ item?.value }})
                                                </span>
                                            </li>
                                        </ol>
                                    </template> -->
                                </MeterGroup>
                            </div>
                            <div v-if="parsable" class="flex flex-row w-full mt-auto justify-end items-end px-5">
                                <Button :label="$t('parse')" @click="nextParseStep(2, activateCallback)" />
                            </div>
                        </div>
                    </div>
                    
                </StepPanel>
                <StepPanel v-slot="{ activateCallback }" :value="2">
                    <DataTable v-model:selection="deactivationSelect" dataKey="id" :rowClass="deactRowClass" :value="deactivationList" tableStyle="min-width: 50rem" class="py-5 mx-4" showGridlines>
                        <Column field="parsing_id" header="ID"></Column>
                        <Column field="title" header="Название"></Column>
                        <Column field="company_name" header="Компания"></Column>
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    </DataTable>
                    <div class="flex flex-row w-full mt-auto justify-end items-end px-4 pb-4">
                        <Button :label="$t('next')" icon="pi pi-arrow-right" iconPos="right" @click="nextParseStep(3, activateCallback)" />
                    </div>
                </StepPanel>
                <StepPanel v-slot="{ activateCallback }" :value="3">
                    <DataTable v-model:selection="changingSelect" :value="changeList" dataKey="id" tableStyle="min-width: 50rem" class="py-5 mx-4" showGridlines>
                        <Column field="parsing_id" header="ID"></Column>
                        <Column field="title" header="Название">
                            <template #body="{ data }">
                                <a :href="route('vacancy.edit', data.id)" target="_blank">{{ data.title }}</a>
                            </template>
                        </Column>
                        <Column field="number_of_workplaces" header="Рабочих мест"></Column>
                        <Column field="parsed.number_of_workplaces" header="Рабочих мест"></Column>
                        <Column field="allowed_genders" header="Пол">
                            <template #body="{ data }">
                                <div class="flex flex-row gap-0">
                                    <template v-for="gender in data.allowed_genders?.sort()">
                                        <span v-if="genderIcons[gender]"
                                            class="material-symbols-outlined text-blue-600" :class="genderIcons[gender]?.class" translate="no">{{
                                            genderIcons[gender]?.icon }}
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </Column>
                        <Column field="parsed.allowed_genders" header="Пол">
                            <template #body="{ data }">
                                <div class="flex flex-row gap-0">
                                    <template v-for="gender in data.parsed.allowed_genders">
                                        <span v-if="genderIcons[gender]"
                                            class="material-symbols-outlined text-blue-600" :class="genderIcons[gender]?.class" translate="no">{{
                                            genderIcons[gender]?.icon }}
                                        </span>
                                        <span v-else>
                                            {{ gender }}
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </Column>
                        <Column header="В" field="max_age"></Column>
                        <Column header="В" field="parsed.max_age"></Column>
                        <Column header="Б" field="base_rate"></Column>
                        <Column header="Б" field="parsed.base_rate"></Column>
                        <Column header="С" field="student_rate"></Column>
                        <Column header="С" field="parsed.student_rate"></Column>
                        <Column header="Ж" field="housing_cost"></Column>
                        <Column header="Ж" field="parsed.housing_cost"></Column>
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    </DataTable>
                    <div class="flex flex-row w-full mt-auto justify-between px-4 pb-4">
                        <Button :label="$t('back')" severity="secondary" icon="pi pi-arrow-left" @click="activateCallback(2)" />
                        <Button :label="$t('proceed')" icon="pi pi-arrow-right" iconPos="right" @click="nextParseStep(4, activateCallback)" />
                    </div>
                </StepPanel>
            </StepPanels>
            <StepPanel v-slot="{ activateCallback }" :value="4">
                <div class="py-1">
                    <Panel header="Деактивированы" toggleable v-if="deactivated?.length" class="m-4">
                        <ul>
                            <li v-for="item in deactivated"><a :href="route('vacancy.edit', item.id)" target="_blank">{{ item.title }}</a></li>
                        </ul>
                    </Panel>
                    <Panel header="Изменены" toggleable v-if="changed?.length" class="m-4">
                        <table class="table-auto border border-collapse w-100">
                            <thead>
                                <tr>
                                    <th colspan="7">Изменения</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in changed">
                                    <td class="border"><a :href="route('vacancy.edit', item.id)" target="_blank">{{ item.title }}</a></td>
                                    <td class="border text-center" v-for="(value, column) in item.changes">
                                        <span v-if="column == 'allowed_genders'" class="flex flex-row justify-between align-center">
                                            <template v-for="gender in value.from?.sort()">
                                                <span v-if="genderIcons[gender]"
                                                    class="material-symbols-outlined text-blue-600" :class="genderIcons[gender]?.class" translate="no">{{
                                                    genderIcons[gender]?.icon }}
                                                </span>
                                            </template>
                                            =>
                                            <template v-for="gender in value.to?.sort()">
                                                <span v-if="genderIcons[gender]"
                                                    class="material-symbols-outlined text-blue-600" :class="genderIcons[gender]?.class" translate="no">{{
                                                    genderIcons[gender]?.icon }}
                                                </span>
                                            </template>
                                        </span>
                                        <span class="px-[2px]" v-else>{{ value.from }} => {{ value.to }}</span>
                                        
                                    </td>
                                    <!-- <td class="border text-center">{{ item.from }}</td>
                                    <td class="border text-center">{{ item.to }}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </Panel>
                </div>
            </StepPanel>
        </Stepper>
    </div>
</template>

<script>
import { Link, Head, router, useForm } from "@inertiajs/vue3";
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";
import { useConfirm } from 'primevue/useconfirm';
import { trans } from 'laravel-vue-i18n';

export default {
    layout: PrimeLayout,
    components: {
        Link, Head
    },
    props: {
        parsed: Array,
        step: [String, Number]
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const genders = [
            {
                icon: 'male',
                class: 'material-symbols-outlined text-blue-600',
                value: 1
            },
            {
                icon: 'female',
                class: 'material-symbols-outlined text-pink-600',
                value: 2
            },
            {
                icon: 'family_restroom',
                class: 'material-symbols-outlined text-indigo-600',
                value: 3
            }
        ];

        const genderIcons = {
            1: {
                icon: 'male',
                class: 'text-blue-600'
            },
            2: {
                icon: 'female',
                class: 'text-pink-600'
            },
            3: {
                icon: 'family_restroom',
                class: 'text-indigo-600 text-xl'
            }
        };

        return { hasRole, hasPermission, genders, genderIcons };
    },
    data() {
        return {
            //step: '1',
            parsable: null,
            parsableError: false,
            file: null,
            deactivationList: [],
            deactivated: [],
            changeList: [],
            changed: [],
            deactivationSelect: null,
            changingSelect: null,
            newList: []
        }
    },
    methods: {
        onFileSelect(event) {
            this.parsableError = false;

            const file = event.files[0];

            const formData = new FormData();
            formData.append("file", file);

            axios.post(route('vacancy-parser.upload'), formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                console.log(response.data);
                const parsable = response.data.parsable;
                const statistics = response.data.statistics;

                this.parsable = parsable;
                this.parsable.count = statistics.count;
                this.parsable.statistics = [
                    {
                        label: 'Активные',
                        color: '#34d399',
                        value: statistics.active ?? 0
                    },
                    {
                        label: 'Не активные',
                        color: '#60a5fa',
                        value: statistics.inactive ?? 0
                    },
                ];
                this.file = response.data.file;
            })
            .catch(() => {
                this.parsableError = true;
            });
        },
        async nextParseStep(step, activateCallback = null) {
            switch (step) {
                case 2:
                    const parsed = await this.parse();
                    if (parsed) {
                        const deact = await this.listDeact();
                        const change = await this.listChange();
                    }
                    break;
                case 3:
                    break;
                case 4:
                    const proceed = await this.proceed();
            }

            if (activateCallback)
                activateCallback(step);
        },
        async parse() {
            const data = {
                action: 'parse',
                params: {
                    tmpFile: this.file,
                    parsingMethod: this.parsable.parsing_method,
                    parsable_id: this.parsable.id
                }
            };

            try {
                const response = await axios.post(route('vacancy-parser.action'), data);
                return response.data.success;
            }
            catch (error) {
                console.error(error);
            }

            return false;
        },
        async proceed() {
            const data = {
                action: 'proceed',
                params: {
                    deactivate: this.deactivationSelect,
                    change: this.changingSelect,
                }
            };

            try {
                const response = await axios.post(route('vacancy-parser.action'), data);
                //console.log(response.data);
                if (response.data.success) {
                    this.changed = response.data.changed;
                    this.deactivated = response.data.deactivated;
                }
                return response.data.success;
            }
            catch (error) {
                console.error(error);
            }

            return false;
        },
        async listDeact() {
            try {
                const response = await axios.post(route('vacancy-parser.action'), { action: 'listDeact', params: { parsable_id: this.parsable.id }});
                if (response.data.success) {
                    this.deactivationList = response.data.list.filter(x => x.parsing_id);
                    this.deactivationSelect = response.data.list.filter(x => x.parsing_id);
                }
                return response.data.success;
            }
            catch (error) {
                console.error(error);
            }

            return false;
        },
        async listChange() {
            try {
                const response = await axios.post(route('vacancy-parser.action'), { action: 'listChange', params: { parsable_id: this.parsable.id }});
                if (response.data.success) {
                    this.changeList = response.data.list;
                    //this.changeSelect = response.data.list.filter(x => x.parsing_id);
                }
                return response.data.success;
            }
            catch (error) {
                console.error(error);
            }

            return false;
        },
        deactRowClass(data) {
            if (!data.parsing_id) {
                return ['!bg-orange-200'];
            }
        },
        parsedGenders(gendersString) {
            if (gendersString) {
                const genderIds = {
                    'М': 1,
                    'Ж': 2,
                    'М+Ж': 3
                }

                var genders = gendersString.split(' '); //странный пробел
                genders = genders.map(gender => {
                    if (genderIds[gender])
                        return genderIds[gender];
                    return gender;
                });
                return genders.sort();
            }
            return []
        }
    },
    watch: {
        deactivationSelect(val) {
            //console.log(val);
        }
    },
    mounted() {
        // this.parsable = {
        //     "id": 31,
        //     "name": "123321",
        //     "logo": "http://localhost/placeholders/1cffffffb00a65aw160h160.png",
        //     "required_workdays": 10,
        //     "v_contacts": [],
        //     "recruiter_description": null,
        //     "parsing_keywords": "gremi.planfix.com,Список вакансий",
        //     "parsing_method": "tryVacancyParse",
        //     "count": 6,
        //     "statistics": [
        //         {
        //             "label": "Активные",
        //             "color": "#34d399",
        //             "value": 3
        //         },
        //         {
        //             "label": "Не активные",
        //             "color": "#60a5fa",
        //             "value": 1
        //         }
        //     ]
        // }
        this.nextParseStep(this.step);
        //if (this.step == 2)
            //this.listDeact();
        //this.showFlashToast();
    }
}
</script>