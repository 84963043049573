<template>
    <Drawer v-model:visible="visible" :blockScroll="true" header="Уведомления" position="right" class="!bg-[#f1f5f9] !w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <template #header class="test">
            <div class="p-drawer-title">Уведомления</div>
        </template>
        <div class="flex gap-2 pt-2">
            <InputGroup>
                <InputText :placeholder="$t('search')" v-model="search" class="w-full" />
                <InputGroupAddon @click.prevent="readAllNotifications()" class="p-0! cursor-pointer hover:bg-[#f1f5f9]">
                    <span class="material-symbols-outlined text-[1.25rem]" translate="no">notification_multiple</span>
                </InputGroupAddon>
            </InputGroup>
        </div>
        <div class="flex flex-col flex-wrap gap-2 pt-2">
            <template v-for="notification in notifications">
                <template v-if="notification.component">
                    <component v-if="(showOnly !== null && showOnly.includes(notification.id)) || showOnly === null" :is="TemplateNotification[notification.component]" :notification="notification" @read-notification="readNotification"></component>
                </template>
                <Notification 
                    v-else
                    :notification="notification"
                    @read-notification="readNotification"
                />
            </template>
        </div>
    </Drawer>
</template>

<script>
import { ref } from "vue";
import { usePermissions } from '../Composables/usePermissions';
import Notification from "../Components/Notification.vue";
import * as TemplateNotification from "@/Components/Notifications";

export default {
    components: {
        Notification
    },
    props: {
        active: {
            type: Boolean,
            required: true
        },
        notifications: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        return { hasRole, hasPermission, TemplateNotification };
    },
    data() {
        //console.log(this);
        return {
            search: null,
            showOnly: null,
        };
    },
    created() {
    },
    methods: {
        readNotification(id) {
            axios.patch(route('notifications.mark', id)).then((response) => {
                if (response.data) {
                    const index = this.notifications.findIndex((n) => n.id === id);
                    if (index > -1) {
                        this.notifications.splice(index, 1);
                        if (!this.notifications.length) {
                            this.visible = false;
                        }
                    }
                }
            });
        },
        readAllNotifications() {
            axios.patch(route('notifications.mark-all')).then((response) => {
                if (response.data) {
                    this.notifications.length = 0;
                }
            });
        },
        searchNotification(title) {
            title = title.trim();
            if (title) {
                const lowerCaseTitle = title.toLowerCase();
                this.showOnly = this.notifications
                    .filter(notification =>  notification.title.toLowerCase().includes(lowerCaseTitle))
                    .map(notification => notification.id);
            } else {
                this.showOnly = null;
            }
        }
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        }
    },
    watch: {
        'search': function(val) {
            this.searchNotification(val);
        }
    },
}
</script>